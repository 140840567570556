<template>
  <v-container class="fill-height">
    <div>
      <div v-if="pending">...Подождите немного</div>
      <div v-if="error">
        <div class="error--text mb-2">{{ error }}</div>
        <v-btn color="primary" outlined to="/">На главную</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { apiClient } from "@/api";
export default {
  name: "PageAuth",
  data() {
    return {
      pending: true,
      error: "",
    };
  },
  metaInfo: {
    title: "Авторизация",
  },
  created() {
    this.authorize();
  },
  methods: {
    async authorize() {
      const { code } = this.$route.query;
      if (!code) {
        this.pending = false;
        this.error = `Отсутствует параметр code. Пожалуйста, сообщите нам об этой ошибке`;
        return;
      }
      this.pending = true;
      try {
        const { data } = await apiClient({
          method: "GET",
          url: "/auth/complete",
          params: {
            code,
          },
        });
        await this.$store.dispatch("user/setAuth", data);
        const nextUrl = localStorage?.getItem("next_redirect");
        if (nextUrl) {
          localStorage?.removeItem("next_redirect");
          this.$router.push(nextUrl);
        } else {
          this.$router.push("/");
        }
      } catch (error) {
        this.error =
          error?.message ??
          "Возникла непредвиденная ошибка, пожалуйста повторите запрос или обратитесь к администратору сервиса";
        this.pending = false;
      }
    },
  },
};
</script>

<style></style>
